import React, { FC, ReactNode } from 'react';

interface IErrorState {
  id: string;
  error: string | boolean;
  children?: ReactNode;
}

export const OneXErrorState: FC<IErrorState> = (props: IErrorState): JSX.Element => {
  const { id, error } = props;
  const inputErrorClass = `${error ? '-oneX-widget--err' : ''}`;

  return (
    <div data-testid={id + '-input-error-test-id'} className={inputErrorClass}>
      {props.children}
    </div>
  );
};