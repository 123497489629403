import React, {FC, useCallback, useEffect, useId, useRef, useState} from 'react';
import {OneXColumn} from '../../components/oneX/OneXColumn';
import {OneXRow} from '../../components/oneX/OneXRow';
import {Typography} from '../../components/oneX/OneXTypogrpahy';
import {useOneXSubscription} from '../../hooks/useOneXSubscription';
import {useForm} from '../../hooks/useForm';
import {OneXFloatingTextField} from '../../components/oneX/OneXFloatingTextField';
import {OneXButton} from '../../components/oneX/OneXButton';
import {IRegisterStudent, IRegisterStudentResponse} from '../../entity/schema/register';
import { locationOptions, registerConstraints } from '../../utils/formValidator/schema';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {useRegisterStudentMutation} from '../api/apiSlice';
import {RootState} from '../../app/store';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {OneXLoader} from '../../components/oneX/OneXLoader';
import {onErrorNavigate, unprocessableEntityNotifyError} from '../helper';
import { setRegStudentInfo, setExternalClaimId } from './register-slice';
import OneXDropDown from '../../components/oneX/OneXDropDown';
// import { DateInput } from '../../entity';

export function nonEmptyValues(value: string) {
    if (value.length>0) return value;
    return value;
}

export function onlyNumericValues(value: string) {
    if (/^[0-9]+$/.test(value)) return value;
    return value.replace(/[^0-9]+$/, '');
}

export function onlyNumericCurrencyValues(value: string) {
    if (/^[0-9]+$/.test(value)) return value;
    return value.replace(/[^0-9.]+$/, '');
}

export function nameSnFun(value: string) {
    if (/^[a-zA-Z ]+$/.test(value)) return value;
    return value.replace(/[<>^!%+=$?{}]+/g, '');
}

export function claimNumberSnFun(value: string) {
    if (/^[a-zA-Z0-9-]+$/.test(value)) return value;
    return value.replace(/[^a-zA-Z0-9-]+$/, '');
}

export function dateOfLossSnFun(value: string) {
    const dateOfLoss = value.replace(/[^\d]/g, '');
    const dateOfLossLength = dateOfLoss?.length;
    if (dateOfLossLength < 2) return dateOfLoss;
    if (dateOfLossLength < 4) {
        return `${dateOfLoss.slice(0, 3)}-${dateOfLoss.slice(3)}`;
    }
    return `${dateOfLoss.slice(0, 2)}-${dateOfLoss.slice(2, 4)}-${dateOfLoss.slice(4, 8)}`;
}

export const RegisterForm: FC = (): JSX.Element => {
    // const {isError: isAuthError, error: authError} = useUserAuthQuery(null);
    //
    // const [validDate, setValidDate] = useState(false);
    // const [date, setDate] = useState('');
    // const [nextDisabled, setNextDisabled] = useState(true);
    // const [dateNotUpdatedYet, setDateNotUpdatedYet] = useState(true);

    const id = useId();
    // const navigate = useNavigate();
    const registrationData = useAppSelector((state) => state.claim);
    const dispatch = useAppDispatch();
    const selfRef = useRef<HTMLFormElement>(null);
    // const registerStudentResponse: IRegisterStudentResponse | undefined = useSelector(
    //     (state: RootState) => state.claim.response,
    // );
    useOneXSubscription(selfRef);

    // useEffect(() => {
    //     dispatch(
    //         setFileUploadStatus({
    //             isFileUploaded: false,
    //         }),
    //     );
    //     adobeAnalytics().tagClaimValidate(registerStudentResponse?.externalClaimID || '');
    // }, [registerStudentResponse]);

    const handleOnSubmit = (value: IRegisterStudent) => {
        registerStudent({
            ...value,
            location: value.location.trim(),
            parentFirstName: value.parentFirstName.trim(),
            parentLastName: value.parentLastName.trim(),
            parentEmail: value.parentEmail,
            parentPhone: value.parentPhone,
            studentfname: value.studentfname.trim(),
            studentlname: value.studentlname.trim(),
            studentemail: value.studentemail,
            studentPhone: value.studentPhone,
            age: value.age,
            studentGrade: value.studentGrade,
            schoolname: value.schoolname,
            studentVideoConsent: value.studentVideoConsent,
            studentAdditionalInfo: value.studentAdditionalInfo,
            studentFood: value.studentFood,
            studentFoodAllergy: value.studentFoodAllergy,
        })
            .unwrap()
            .then((res: IRegisterStudentResponse) => {
                dispatch(
                    setRegStudentInfo({
                        studentInfo: value,
                    }),
                );
            });

        console.log('Registering student');
    };
    const [registerStudent, {data: studentResponse, isLoading, isSuccess, isError, error}] =
        useRegisterStudentMutation();

    //TODO clean up after testing
    const initialFormValues: IRegisterStudent = {
        location: 'Bloomington',
        parentFirstName: 'John',
        parentLastName: 'Doe',
        parentEmail: 'john.doe@example.com',
        parentPhone: '123-456-7890',
        studentfname: 'Jane',
        studentlname: 'Doe',
        studentemail: 'jane.doe@example.com',
        studentPhone: '098-765-4321',
        age: '10',
        pronouns: 'She/Her',
        studentGrade: '5',
        schoolname: 'Springfield Elementary',
        studentVideoConsent: 'Yes, John Doe',
        studentAdditionalInfo: 'No additional info',
        studentFood: 'Yes',
        studentFoodAllergy: 'None',
        studentSickConsent: 'Yes, John Doe',
        studentPickupInfo: 'Jane Doe, Mother, 123-456-7890',
    };

    const {dirty, handleSubmit, handleBlur, handleChangeAndValidate, data, errors, disable} =
        useForm<IRegisterStudent>({
            initialValues: initialFormValues, // registrationData.info || ({} as IRegisterStudent),
            validations: registerConstraints,
            onSubmit: () => handleOnSubmit(data),
        });

    // useEffect(() => {
    //     if (registerStudentResponse) {
    //
    //         // navigate(ResourcePage.summaryReport);
    //     }
    // }, [navigate, registerStudentResponse]);

    // const updateDateOfLoss = useCallback((newDate: string) => {
    //     if (newDate.length > 10) {
    //         newDate = newDate.substring(0, 10);
    //     }
    //     const formattedDate = moment(newDate, 'MM-DD-YYYY', true);
    //     setDate(newDate);
    //     setValidDate(formattedDate.isValid() && formattedDate.isSameOrBefore());
    //     if (newDate.length > 0) {
    //         setDateNotUpdatedYet(false);
    //         //errors.dateOfLoss = "Date Of Loss is a Required Field";
    //     }
    //     // else if (!newDate) {
    //     //   errors.dateOfLoss = "Date Of Loss is a Required Field";
    //     // }
    // }, []);

    // useEffect(() => {
    //     setNextDisabled(!validDate);
    // }, [validDate]);

    //showing valid error message for Date of loss

    return (
        <>
            <br/>
            {/*{onErrorNavigate(isError || isAuthError, error || authError, navigate)}*/}
            <Typography id={'-info-header-primary'} heading={'h3'} style={{paddingTop: '1.5vh'}}>
                {'Join us at the Tech Astra 2025'}
            </Typography>
            <Typography
              id={'reg-subtitle'}
              body={'primary'}
              style={{paddingTop: '1vh'}}>
                {'Provide us some information to secure your spot.'}
            </Typography>
            <br/>

            {unprocessableEntityNotifyError(isError, error, id)}
            <form ref={selfRef} onSubmit={handleSubmit} noValidate>
                <OneXDropDown
                  name={'location'}
                  label={'Location'}
                  type={'string'}
                  disable={isLoading}
                  option={locationOptions}
                  value={data.location || ''}
                  error={(dirty.location && errors.location) || ''}
                  onBlur={handleBlur('location')}
                  onInputChange={handleChangeAndValidate<string>('location')}
                />
                <br/>
                <Typography
                  id={'parent-info-title'}
                  heading={'h5'}
                  style={{paddingTop: '1vh'}}> {'Parent/Guardian Information'} </Typography>
                <br/>
                <OneXRow>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-fn-input'}
                            name={'parentFirstName'}
                            value={data.parentFirstName || ''}
                            label={'First Name'}
                            type={'string'}
                            // max={11}
                            // max={9}
                            onBlur={handleBlur('parentFirstName')}
                            title={'Enter first name'}
                            error={(dirty.parentFirstName && errors.parentFirstName) || ''}
                            onInputChange={handleChangeAndValidate<string>('parentFirstName', (value) =>
                              nonEmptyValues(value),
                            )}
                            disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-ln-input'}
                            name={'parentLastName'}
                            value={data.parentLastName || ''}
                            label={'Last Name'}
                            type={'string'}
                            // max={5}
                            error={(dirty.parentLastName && errors.parentLastName) || ''}
                            onBlur={handleBlur('parentLastName')}
                            onInputChange={handleChangeAndValidate<string>('parentLastName', (value) =>
                              nonEmptyValues(value),
                            )}
                            disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-email-input'}
                            name={'parentEmail'}
                            value={data.parentEmail || ''}
                            label={'Email'}
                            type={'text'}
                            error={(dirty.parentEmail && errors.parentEmail) || ''}
                            onInputChange={handleChangeAndValidate<string>('parentEmail', (value) =>
                              nonEmptyValues(value),
                            )}
                            onBlur={handleBlur('parentEmail')}
                            disable={isLoading}
                        />
                        {/* <OneXNotification
                           id={'file-notification-'}
                           icon={NotificationIconVariant.ERROR}
                           notificationText={'Please enter at least the first 3 characters of the policyholders last name or organization name'}
                        />                     */}
                       {/*<div  className="-oneX-textarea-sensitiveText">Please enter a valid email address.</div>*/}
                    </OneXColumn>
                    <OneXColumn
                        defaultSize={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        customClass={'mp-1'}
                        style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                            data-testid={'parent-phone-input'}
                            name={'parentPhone'}
                            value={data.parentPhone || ''}
                            label={'Phone Number'}
                            type={'text'}
                            max={12}
                            dataFormatter={'phone'}
                            onBlur={handleBlur('parentPhone')}
                            title={'Phone Number'}
                            error={(dirty.parentPhone && errors.parentPhone) || ''}
                            onInputChange={handleChangeAndValidate<string>('parentPhone', (value) =>
                                nonEmptyValues(value),
                            )}
                            disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <br/>
                <Typography
                  id={'student-info'}
                  heading={'h5'}
                  style={{paddingTop: '1vh'}}
                >
                    {'Student\'s Information'}
                </Typography>
                <br/>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-fn-input'}
                          name={'studentfname'}
                          value={data.studentfname || ''}
                          label={'First Name'}
                          type={'string'}
                          // max={11}
                          // max={9}
                          onBlur={handleBlur('studentfname')}
                          title={'Enter first name'}
                          error={(dirty.studentfname && errors.studentfname) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentfname', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-ln-input'}
                          name={'studentlname'}
                          value={data.studentlname || ''}
                          label={'Last Name'}
                          type={'string'}
                          // max={5}
                          error={(dirty.studentlname && errors.studentlname) || ''}
                          onBlur={handleBlur('studentlname')}
                          onInputChange={handleChangeAndValidate<string>('studentlname', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-email-input'}
                          name={'studentemail'}
                          value={data.studentemail || ''}
                          label={'Email'}
                          type={'text'}
                          error={(dirty.studentemail && errors.studentemail) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentemail', (value) =>
                            nonEmptyValues(value),
                          )}
                          onBlur={handleBlur('studentemail')}
                          disable={isLoading}
                        />
                        {/* <OneXNotification
                           id={'file-notification-'}
                           icon={NotificationIconVariant.ERROR}
                           notificationText={'Please enter at least the first 3 characters of the policyholders last name or organization name'}
                        />                     */}
                        {/*<div  className="-oneX-textarea-sensitiveText">Please enter a valid email address.</div>*/}
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-phone-input'}
                          name={'studentPhone'}
                          value={data.studentPhone || ''}
                          label={'Phone Number'}
                          type={'text'}
                          max={12}
                          dataFormatter={'phone'}
                          onBlur={handleBlur('studentPhone')}
                          title={'Phone Number'}
                          error={(dirty.studentPhone && errors.studentPhone) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentPhone', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-age-input'}
                          name={'age'}
                          value={data.age || ''}
                          label={'Age'}
                          type={'tel'}
                          max={2}
                          // max={9}
                          onBlur={handleBlur('age')}
                          title={'Enter student age'}
                          error={(dirty.age && errors.age) || ''}
                          onInputChange={handleChangeAndValidate<string>('age', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12} sm={12} md={12} lg={6} xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-pronouns-input'}
                          name={'pronouns'}
                          value={data.pronouns || ''}
                          label={'Pronouns'}
                          type={'text'}
                          max={20}
                          onBlur={handleBlur('pronouns')}
                          title={'Enter student pronouns'}
                          error={(dirty.pronouns && errors.pronouns) || ''}
                          onInputChange={handleChangeAndValidate<string>('pronouns', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-grade-input'}
                          name={'studentGrade'}
                          value={data.studentGrade || ''}
                          label={'School Grade'}
                          type={'text'}
                          // max={5}
                          error={(dirty.studentGrade && errors.studentGrade) || ''}
                          onBlur={handleBlur('studentGrade')}
                          onInputChange={handleChangeAndValidate<string>('studentGrade', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-school-input'}
                          name={'schoolname'}
                          value={data.schoolname || ''}
                          label={'School Name'}
                          type={'text'}
                          error={(dirty.schoolname && errors.schoolname) || ''}
                          onInputChange={handleChangeAndValidate<string>('schoolname', (value) =>
                            nonEmptyValues(value),
                          )}
                          onBlur={handleBlur('schoolname')}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <Typography
                  id={'student-food-info'}
                  body={'primary'}
                  style={{paddingTop: '1vh'}}>
                    {'1. A snack will be provided, and we aim to accommodate most food restrictions. Students are more ' +
                      'than welcome to bring their own snack and use our refrigerators and microwaves as needed. Please ' +
                      'select yes if your student plans to eat the provided snack.'}
                </Typography>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-food-input'}
                          name={'studentFood'}
                          value={data.studentFood || ''}
                          label={'Food'}
                          type={'string'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentFood')}
                          title={'Snack'}
                          error={(dirty.studentFood && errors.studentFood) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentFood', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-allergy-input'}
                          name={'foodAllergies'}
                          value={data.studentFoodAllergy || ''}
                          label={'Food Allergies'}
                          type={'text'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentFoodAllergy')}
                          title={'Food Allergies'}
                          error={(dirty.studentFoodAllergy && errors.studentFoodAllergy) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentFoodAllergy', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <Typography
                  id={'student-sick-consent'}
                  body={'primary'}
                  style={{paddingTop: '1vh'}}>
                    {'2. My child will stay home if—within 5 days of the event—they have symptoms, have tested positive, ' +
                      'have tests pending or had close contact/live with someone who tested positive. I will notify ' +
                      'organizers if this occurs. (Type “Yes” followed by your name)'}
                </Typography>
                <OneXRow>

                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-sick-input'}
                          name={'studentSickConsent'}
                          value={data.studentSickConsent || ''}
                          label={'Sick Consent'}
                          type={'string'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentSickConsent')}
                          title={'Sick Consent'}
                          error={(dirty.studentSickConsent && errors.studentSickConsent) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentSickConsent', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <Typography
                  id={'student-video-consent'}
                  body={'primary'}
                  style={{paddingTop: '1vh'}}>
                    {'3. I agree to let State Farm video, interview and photograph my child-- including the use of their ' +
                      'name and voice-- for things including, but not limited to, Tech Astra recap and promo videos to help ' +
                      'get future students interested in STEM fields. (Type “Yes” followed by your name)'}
                </Typography>
                <OneXRow>

                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-video-input'}
                          name={'studentVideoConsent'}
                          value={data.studentVideoConsent || ''}
                          label={'Video Consent'}
                          type={'text'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentVideoConsent')}
                          title={'Video Consent'}
                          error={(dirty.studentVideoConsent && errors.studentVideoConsent) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentVideoConsent', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <Typography
                  id={'student-pickup-info'}
                  body={'primary'}
                  style={{paddingTop: '1vh'}}>
                    {'4. For Security purposes, if you are authorizing anyone else for student pickup, please list ' +
                      'their name, relationship to the student, and phone number.'}
                </Typography>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{paddingTop: '2vh'}}
                    >
                        <OneXFloatingTextField
                          data-testid={'student-pickup-input'}
                          name={'studentPickupInfo'}
                          value={data.studentPickupInfo || ''}
                          label={'Pickup Info'}
                          type={'text'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentPickupInfo')}
                          title={'Pickup Info'}
                          error={(dirty.studentPickupInfo && errors.studentPickupInfo) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentPickupInfo', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <Typography
                  id={'student-additional-info'}
                  body={'primary'}
                  style={{ paddingTop: '1vh' }}>
                    {'5. Any additional information the Tech Astra should know about your student?'}
                </Typography>
                <OneXRow>
                    <OneXColumn
                      defaultSize={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      customClass={'mp-1'}
                      style={{ paddingTop: '2vh' }}
                    >
                        <OneXFloatingTextField
                          data-testid={'additional-info-input'}
                          name={'studentAdditionalInfo'}
                          value={data.studentAdditionalInfo || ''}
                          label={'Additional Details'}
                          type={'text'}
                          // max={12}
                          // dataFormatter={'phone'}
                          onBlur={handleBlur('studentAdditionalInfo')}
                          title={'Additional Details'}
                          error={(dirty.studentAdditionalInfo && errors.studentAdditionalInfo) || ''}
                          onInputChange={handleChangeAndValidate<string>('studentAdditionalInfo', (value) =>
                            nonEmptyValues(value),
                          )}
                          disable={isLoading}
                        />
                    </OneXColumn>
                </OneXRow>
                <OneXRow style={{justifyContent: 'center', paddingTop: '2vh'}}>
                    <OneXButton
                        id={'register-student-button'}
                        label={'Register'}
                        type={'submit'}
                        actionRole={'primary'}
                        disabled={disable || isLoading}
                    />
                </OneXRow>
                {isLoading && <OneXLoader key={'notification-loader-' + id} show={isLoading}/>}
            </form>
        </>
    );
};