import React, { ChangeEvent, FC } from 'react';
import OneXFieldErrorState from './OneXFieldErrorState';
import { IDropDownOption } from '../../entity/formValidator';
import { OneXErrorState } from './OneXErrorState';

interface IDropDown {
  name: string;
  label: string;
  type: string;
  error: string | boolean;
  value: string;
  option: IDropDownOption[];
  onInputChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (event: ChangeEvent<HTMLSelectElement>) => void;
  disable?: boolean;
}

const dropDownOptions = (options: IDropDownOption[]) => {
  return options.map((option) => {
    return (
      <option key={option.value} value={option.key}>
        {option.value}
      </option>
    );
  });
};

const OneXDropDown: FC<IDropDown> = (props: IDropDown): JSX.Element => {
  const { name, label, error, value, option, onInputChange, onBlur, disable } = props;

  const fieldSetClass = '-oneX-fieldset';
  const dropDownClass = '';

  return (
    <OneXErrorState id={name} error={error}>
      <fieldset className={fieldSetClass}>
        <div className={dropDownClass}>
          <label htmlFor={name} className='-oneX-dropdown__floatingLabel'>
            {label}
          </label>
          <select
            id={name}
            name={name}
            className='-oneX-dropdown'
            value={value}
            onChange={onInputChange}
            onBlur={onBlur}
            disabled={disable}
          >
            {dropDownOptions(option)}
          </select>
        </div>
      </fieldset>
      {error && <OneXFieldErrorState message={error} />}
    </OneXErrorState>
  );
};

export default OneXDropDown;
