import React, { FC } from 'react';

export interface IErrorState {
  message: string | boolean;
}

const OneXFieldErrorState: FC<IErrorState> = (props: IErrorState): JSX.Element => {
  return (
    <>
      <div id='question-error-msg-1' className='form-field-err -oneX-widget__err-text'>
        {props.message}
      </div>
    </>
  );
};
export default OneXFieldErrorState;