import {ChangeEvent, FocusEvent, FormEvent, useState} from 'react';

interface Validation {
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: string;
    message: string;
  };
  custom?: {
    isValid: (value: string) => boolean;
    message: string;
  };
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;
type DirtyRecord<T> = Partial<Record<keyof T, boolean>>;
type Validations<T> = Partial<Record<keyof T, Validation>>;

interface UseFormParams<T extends Record<keyof T, any>> {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
  onSubmit?: () => void;
}

export const useForm = <T extends Record<keyof T, any>>(options?: UseFormParams<T>) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T),
    [errors, setErrors] = useState<ErrorRecord<T>>({} as T),
    [dirty, setDirty] = useState<DirtyRecord<T>>({} as T),
    [disable, setDisable] = useState(true);

  // Needs to extend unknown so we can add a generic to an arrow function
  const handleChange =
    <S>(key: keyof T, sanitizeFn?: (value: string) => S) =>
    (e: ChangeEvent<HTMLInputElement & HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });
    };

  const handleBlur = (key: keyof T) => () => {
    setDirty((prevState) => {
      prevState = {
        ...dirty,
        [key]: true,
      };
      return prevState;
    });
    const validations = options?.validations;
    if (validations) {
      validate(validations, data);
    }
  };

  const handleOnFocus = (key: keyof T) => (e: FocusEvent<HTMLElement>) => {
    setDirty((prevState) => {
      prevState = {
        ...dirty,
        [key]: true,
      };
      return prevState;
    });
    const validations = options?.validations;
    if (validations) {
      validate(validations, data);
    }
  };

  const validate = <T>(validations: Validations<T>, localData: T): void => {
    let valid = true;
    const newErrors: ErrorRecord<T> = {} as ErrorRecord<T>;
    for (const key in validations) {
      const value = localData[key];
      const validation = validations[key];

      const pattern = validation?.pattern;
      if (pattern?.value && !RegExp(pattern.value).test(<any>value)) {
        valid = false;
        newErrors[key] = pattern.message;
      }

      const custom = validation?.custom;
      if (custom?.isValid && !custom.isValid(<any>value)) {
        valid = false;
        newErrors[key] = custom.message;
      }
      if (validation?.required?.value && !value) {
        valid = false;
        newErrors[key] = validation?.required?.message;
      }
    }

    if (!valid) {
      setErrors(<any>newErrors);
      setDisable(true);
      // return;
    } else {
      setErrors({});
      setDisable(false);
    }
  };

  const handleChangeAndValidate =
    <S>(key: keyof T, sanitizeFn?: (value: string) => S) =>
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });

      const validations = options?.validations;
      if (validations) {
        validate(validations, {
          ...data,
          [key]: value,
        });
      }
    };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validations = options?.validations;
    if (validations) {
      validate(validations, data);
    }

    if (options?.onSubmit) {
      options.onSubmit();
    }
  };

  return {
    data,
    dirty,
    handleChange,
    handleBlur,
    handleChangeAndValidate,
    handleSubmit,
    handleOnFocus,
    errors,
    disable,
  };
};
