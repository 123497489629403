import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IRegisterStudent, IRegisterStudentResponse } from '../../entity/schema/register';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://vjz7djyl5k.execute-api.us-east-1.amazonaws.com/test'
    // baseUrl: 'https://api.techastraproto.aw.prototype.labs.statefarm'
    // baseUrl: 'http://localhost:8080/v1/watermit/'
  }),
  endpoints: (builder) => ({

    registerStudent: builder.mutation<IRegisterStudentResponse, IRegisterStudent>({
      query: (data: IRegisterStudent) => ({
        url: '/put-rsrc',
        method: 'PUT',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useRegisterStudentMutation,
} = apiSlice;
