import { IDropDownOption } from '../../entity/formValidator';

export const registerConstraints = {
  location: {
    required: {
      value: true,
      message: 'Location is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid location',
    },
  },
  parentFirstName: {
    required: {
      value: true,
      message: 'First Name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid First Name',
    },
  },
  parentLastName: {
    required: {
      value: true,
      message: 'Last name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid Last Name',
    },
  },
  parentEmail: {
    required: {
      value: true,
      message: 'Email is a required field',
    },
    pattern: {
      value: '^\\S+@\\S+\\.\\S+$',
      message: "Please enter a valid email.",
    },
  },
  parentPhone: {
    required: {
      value: true,
      message: 'Phone number is a required field',
    },
    pattern: {
      value: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      message: 'Please enter a 10 digit phone number.',
    },
  },
  studentfname: {
    required: {
      value: true,
      message: 'First Name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid First Name',
    },
  },
  studentlname: {
    required: {
      value: true,
      message: 'Last name is a required field',
    },
    pattern: {
      value: '^[A-Za-z0-9 ]*$',
      message: 'Please enter a valid Last Name',
    },
  },
  studentemail: {
    required: {
      value: true,
      message: 'Email is a required field',
    },
    pattern: {
      value: '^\\S+@\\S+\\.\\S+$',
      message: "Please enter a valid email.",
    },
  },
  studentPhone: {
    required: {
      value: true,
      message: 'Student Phone number is optional',
    },
    pattern: {
      value: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      message: 'Please enter a 10 digit phone number.',
    },
  },
};

export const locationOptions: IDropDownOption[] = [
  { key: 'BLM', value: 'Bloomington' },
  { key: 'ATL', value: 'Atlanta' },
  { key: 'DAL', value: 'Dallas' },
  { key: 'PHX', value: 'Phoenix' }
];