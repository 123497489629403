import { IRegisterStudent, IRegisterStudentResponse } from '../../entity/schema/register';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IRegisterState = {
  info: {
    location: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    studentfname: '',
    studentlname: '',
    studentemail: '',
    studentPhone: '',
    age: '',
    pronouns: '',
    studentGrade: '',
    schoolname: '',
    studentVideoConsent: '',
    studentFood: '',
    studentFoodAllergy: '',
    studentSickConsent: '',
    studentPickupInfo: '',
    studentAdditionalInfo: ''
  },
  response: undefined,
};

interface IRegisterState {
  info: IRegisterStudent | undefined;
  response: IRegisterStudentResponse | undefined;
}

const registerStudentSlice = createSlice({
  name: 'registerStudent',
  initialState: initialState,
  reducers: {
    setRegStudentInfo: (
      state,
      { payload: { studentInfo } }: PayloadAction<{ studentInfo: IRegisterStudent }>,
    ) => {
      state.info = studentInfo;
    },
    setExternalClaimId: (
      state,
      { payload: { response } }: PayloadAction<{ response: IRegisterStudentResponse }>,
    ) => {
      state.response = response;
    },
    resetValidateClaimResponse: (state) => {
      delete state.response;
    },
    resetValidateClaimInfo: (state) => {
      delete state.info;
    },
  },
});

export const {
  setRegStudentInfo,
  setExternalClaimId,
  resetValidateClaimResponse,
  resetValidateClaimInfo,
} = registerStudentSlice.actions;
export default registerStudentSlice.reducer;
